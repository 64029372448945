.navbarBrand {
  display: flex;
  align-items: center;
  margin-left: 64px;
}

.toggle-mobile {
  display: none;
}

.navigation-section {
  margin-left: auto !important;
  margin-right: 64px !important;
  align-items: center !important;
}

.logoText {
  margin: 0;
  margin-left: 16px;
  font-size: 20px;
}

.logo-img {
  width: 48px;
  height: 48px;
}

.book-btn {
  font-size: 14px;
  padding-top: 18px !important;
  padding-bottom: 18px !important;
  padding-left: 35px !important;
  padding-right: 35px !important;
  border-radius: 10px !important;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
  margin-left: 20px !important;
}

.navitem {
  margin: 0 !important;
  margin-bottom: 20px !important;
}

.book-btn:hover {
  background-color: #5465ff !important;
}

.link_underline {
  place-self: center !important;
  text-decoration: none !important;
  position: relative !important;
  margin-right: 20px !important;
  margin-left: 20px !important;
  padding: 0 !important;
}

.link_underline::after {
  position: absolute !important;
  content: "" !important;
  bottom: -3px !important;
  left: 0px !important;
  width: 100% !important;
  height: 1px !important;
  border-bottom: 1px solid !important;
  transform: scaleX(0) !important;
  transform-origin: 100% 100% !important;
  transition: transform 0.6s !important;
  transition-timing-function: cubic-bezier(0.39, 0.575, 0.28, 0.995) !important;
}

.link_underline:hover::after {
  transform: scaleX(1) !important;
  transform-origin: 0 0 !important;
}

.mobile-navigation {
  display: none;
}

.row-center {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.navbar-toggler {
  display: none !important;
}

@media only screen and (max-width: 1280px) {
  .link_underline {
    margin-left: 4px;
    margin-right: 4px;
  }
}

@media only screen and (max-width: 1140px) {
  .navigation-section {
    margin: 0 !important;
  }
  .book-btn {
    padding-left: 18px !important;
    padding-right: 18px !important;
    margin-left: 16px !important;
  }
  .link_underline {
    margin-right: 10px !important;
    margin-left: 10px !important;
  }
}

@media only screen and (max-width: 991px) {
  .navbar-nav {
    display: none !important;
  }
  .mobile-navigation {
    display: flex;
    background-color: white;
    justify-content: center;
    margin-top: 12px !important;
    margin-bottom: 12px !important;
  }
  .toggle-mobile {
    display: block;
    margin-right: 32px;
    cursor: pointer;
  }
  .navbarBrand {
    margin-left: 32px;
    justify-content: space-between !important;
  }
  .container-fluid {
    align-items: center !important;
  }
  .link_underline {
    margin: 0;
  }
  .logoText {
    font-size: 16px !important;
  }
  .logo-img {
    width: 42px;
    height: 42px;
  }
  .navbar {
    position: sticky;
    top: 0;
    z-index: 100;
    background: #f9f9f9 !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
  .navitem {
    margin: 0 !important;
    margin-bottom: 20px !important;
    padding-left: 35px !important;
    padding-right: 35px !important;
  }
}

@media only screen and (max-width: 767px) {
  .toggle-mobile {
    margin-right: 6px;
  }
  .navbarBrand {
    margin-left: 6px;
  }
  .logoText {
    font-size: 16px !important;
  }
  .logo-img {
    width: 42px;
    height: 42px;
  }
}

@media only screen and (max-width: 376px) {
  .toggle-mobile {
    margin-right: 2px !important;
  }
  .navbarBrand {
    margin-left: 2px !important;
  }
  .navigationItemsToggled {
    margin-bottom: 14px;
    margin-top: 14px;
  }
}

@media only screen and (max-height: 780px) {
  .navigationItemsToggled {
    margin-bottom: 12px;
    margin-top: 12px;
  }
  .navitem {
    margin: 0 !important;
    margin-bottom: 12px !important;
  }
}
