.about-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-top: 80px;
  padding-bottom: 80px;
}

.about-img {
  border-radius: 10px;
  width: 345px;
  height: 345px;
}

.image-block {
  display: flex !important;
}

.heading-data {
  font-size: 40px;
  margin-bottom: 24px;
}

.desc-text {
  font-size: 16px;
  margin-bottom: 40px;
  text-align: justify;
}

.know-more-btn {
  text-decoration: none;
  font-size: 14px;
  padding-top: 18px !important;
  padding-bottom: 18px !important;
  padding-left: 35px !important;
  padding-right: 35px !important;
  border-radius: 10px !important;
  transition: 0.25s;
}

.know-more-text {
  margin-right: 12px;
}

.know-more-btn:hover {
  border-color: #fff !important;
  background-color: #4053fd;
  color: #fff !important;
}

.icon-right {
  margin-bottom: 2px;
  margin-left: 12px;
}

@media (max-width: 991px) {
  .image-block {
    margin-bottom: 36px;
    align-items: center;
    justify-content: center;
  }
  .text-block {
    text-align: center;
  }
}

@media (max-width: 767px) {
  .text-block {
    padding-left: 24px;
    padding-right: 24px;
  }
  .desc-text {
  }
  .about-container {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .heading-data {
    font-size: 32px;
    margin-bottom: 24px !important;
  }
}

@media (max-width: 378px) {
  .know-more-btn {
    text-decoration: none;
    font-size: 14px;
    padding-top: 18px !important;
    padding-bottom: 18px !important;
    padding-left: 25px !important;
    padding-right: 25px !important;
    border-radius: 10px !important;
    transition: 0.25s;
  }
}
