.read-more-btn {
  text-decoration: none;
  font-size: 14px;
  padding-top: 18px !important;
  padding-bottom: 18px !important;
  padding-left: 35px !important;
  padding-right: 35px !important;
  border-radius: 10px !important;
  transition: 0.25s;
}

.read-more-text {
  margin-right: 12px;
}

.read-more-btn:hover {
  border-color: #fff !important;
  background-color: #4053fd;
  color: #fff !important;
}

.textual-data {
  min-height: 100px;
  margin-bottom: 12px;
}

.textual-data-no-subheading {
  min-height: 100px;
  margin-bottom: 12px;
}

.list-container {
  margin-top: 18px;
  margin-bottom: 18px;
}

.list-block {
  border: 1px solid #4053fd;
  margin-bottom: 10px;
  margin-top: 10px;
  align-items: center;
  justify-content: center;
}

.list-title {
  text-align: center;
  padding-top: 14px;
}

.service-list-container {
  padding-top: 35px;
  padding-bottom: 25px;
}

.not-from-patna-block {
  background-color: rgba(64, 83, 253, 0.85);
  border-radius: 10px;
  padding-top: 40px;
  padding-bottom: 40px;
  padding-left: 25px;
  padding-right: 25px;
  margin-top: 30px;
}

@media (max-width: 378px) {
  .read-more-btn {
    text-decoration: none;
    font-size: 14px;
    padding-top: 18px !important;
    padding-bottom: 18px !important;
    padding-left: 25px !important;
    padding-right: 25px !important;
    border-radius: 10px !important;
    transition: 0.25s;
  }
}

@media (max-width: 767px) {
  .textual-data {
    margin-bottom: 8px;
  }
  .textual-data-no-subheading {
    margin-bottom: 8px;
    min-height: 50px;
  }
  .not-from-patna-block {
    border-radius: 0px;
  }
  .listHeading {
    text-align: left;
  }
  .mobile-video {
    margin-left: 10px;
    margin-right: 10px;
  }
}
