.mobile-carousel {
  display: none;
}

@media (max-width: 767px) {
  .desktop-carousel {
    display: none;
  }
  .mobile-carousel {
    display: block;
  }
}
