.services-container {
  padding-top: 80px;
  padding-bottom: 80px;
}

.heading-text {
  font-size: 40px;
  text-align: center;
  margin-bottom: 55px;
}

.service-img {
  border-radius: 10px;
  width: 345px;
  height: 345px;
}

.item-img {
  border-radius: 10px;
  width: 345px;
  height: 345px;
}

.text-data {
  min-height: 100px;
  margin-bottom: 36px;
}

.text-data-no-subheading {
  min-height: 100px;
  margin-bottom: 36px;
}

.title-text {
  margin: 0;
  margin-top: 24px;
  margin-bottom: 6px;
  font-size: 20px;
}

.subheading-text {
  font-size: 15px;
  color: rgba(45, 45, 45, 0.8);
}

.service-row {
  align-items: center;
  justify-content: center;
  text-align: center;
}

.service-col {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media (max-width: 1199px) {
  .service-row {
    text-align: center;
  }
}

@media (max-width: 767px) {
  .services-container {
    padding-top: 50px;
    padding-bottom: 50px;
    align-items: center;
    justify-content: center;
  }
  .heading-text {
    font-size: 32px;
    margin-bottom: 40px !important;
  }
  .subheading-text {
    width: 100%;
  }
  .text-data {
    margin-bottom: 16px;
  }
  .text-data-no-subheading {
    margin-bottom: 16px;
    min-height: 50px;
  }
  .item-row {
    display: flex;
    flex-direction: column-reverse !important;
  }
  .item-img {
    margin-left: 0 !important;
  }
  .desc-text {
    margin-top: 20px;
  }
}
