.details-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-top: 80px;
  padding-bottom: 80px;
  padding-left: 180px;
  padding-right: 180px;
}

.detail-items {
  margin-left: 60px;
  margin-right: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.details-text {
  font-size: 20px;
  line-height: 32px;
  width: 85%;
  text-align: center;
  margin-top: 24px;
}

@media (max-width: 991px) {
  .detail-items {
    margin-bottom: 36px;
  }
}
@media (max-width: 767px) {
  .details-container {
    padding-left: 0;
    padding-right: 0;
    padding-top: 50px;
    padding-bottom: 20px;
  }
}
