* {
  padding: 0px;
  margin: 0px;
}
@font-face {
  font-family: "PlusJakartaDisplay-Light";
  src: local("PlusJakartaDisplay"),
    url(assets/fonts/plusjakartasans/PlusJakartaDisplay-Light.otf)
      format("opentype");
}

@font-face {
  font-family: "PlusJakartaDisplay-Regular";
  src: local("PlusJakartaDisplay"),
    url(assets/fonts/plusjakartasans/PlusJakartaDisplay-Regular.otf)
      format("opentype");
}

@font-face {
  font-family: "PlusJakartaDisplay-Medium";
  src: local("PlusJakartaDisplay"),
    url(assets/fonts/plusjakartasans/PlusJakartaDisplay-Medium.otf)
      format("opentype");
}

@font-face {
  font-family: "PlusJakartaDisplay-Bold";
  src: local("PlusJakartaDisplay"),
    url(assets/fonts/plusjakartasans/PlusJakartaText-Bold.otf)
      format("opentype");
}
