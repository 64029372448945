.navigationList {
  list-style-type: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: sticky;
  top: 0;
  z-index: 100;
  background-color: #fff !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.toggleDisplay {
  display: none !important;
  transition: 5s;
}

.navigationListToggled {
  display: none;
}

.navigationItems {
  font-style: 14px !important;
  line-height: 20px !important;
  margin-left: 35px !important;
  margin-right: 35px !important;
  cursor: pointer;
  text-align: center;
  padding-bottom: 10px !important;
  text-decoration: none !important;
  margin-top: 36px !important;
  margin-bottom: 26px !important;
}

.link-underline {
  place-self: center !important;
  text-decoration: none !important;
  position: relative !important;
}

.link-underline::after {
  position: absolute !important;
  content: "" !important;
  bottom: 0px !important;
  left: 0px !important;
  width: 100% !important;
  height: 1px !important;
  border-bottom: 1px solid !important;
  transform: scaleX(0) !important;
  transform-origin: 100% 100% !important;
  transition: transform 0.6s !important;
  transition-timing-function: cubic-bezier(0.39, 0.575, 0.28, 0.995) !important;
}

.link-underline:hover::after {
  transform: scaleX(1) !important;
  transform-origin: 0 0 !important;
}

@media (max-width: 1140px) {
  .navigationItems {
    margin-left: 25px !important;
    margin-right: 25px !important;
  }
}

@media (max-width: 991px) {
  .navigationList {
    display: none;
  }
  .navigationListToggled {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #f9f9f9 !important;
    width: 100%;
    overflow: hidden !important;
  }
  .navigationItemsToggled {
    font-style: 14px !important;
    cursor: pointer;
    text-align: center;
    padding-bottom: 10px !important;
    text-decoration: none !important;
    margin-bottom: 20px;
    margin-top: 20px;
  }
}
