.testimonial-container {
  padding-top: 80px;
  padding-bottom: 80px;
  overflow: hidden !important;
}

.heading-text {
  font-size: 40px;
  text-align: center;
  margin-bottom: 55px;
}

.testimonial-row {
  align-items: center;
  justify-content: center;
  text-align: center;
}

.img-block {
  border-radius: 10px;
  width: 345px;
  height: 345px;
  background-color: #6675ff;
  opacity: 10%;
}

.testimonial-img {
  width: 140px !important;
  border-radius: 50%;
}

.testimonial-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.myCarousel {
  background: #fafafa;
  margin-top: -6%;
  width: 75%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 6%;
  padding-bottom: 8%;
  padding-left: 5%;
  padding-right: 5%;
  border: 1px solid #ddd;
  height: auto;
}

.user-name {
  margin-top: 20px;
}

.carousel .control-dots {
  padding-left: 5px !important;
  outline: 0;
}

.myCarousel h3 {
  font-size: 20px;
}

.myCarousel h4 {
  font-size: 15px;
}

.myCarousel p {
  font-weight: 100 !important;
  line-height: 29px !important;
  color: #222;
  font-size: 15px;
  font-family: sans-serif;
  min-height: 67px;
}

.myCarousel p:before {
  content: "“";
  color: #aaa;
  font-size: 26px;
  font-family: monospace;
  font-weight: 100;
}

.myCarousel p:after {
  content: "”";
  color: #aaa;
  font-size: 26px;
  font-family: monospace;
  font-weight: 100;
  line-height: 0;
}

.carousel .control-dots .dot {
  box-shadow: none !important;
  background: #454545 !important;
  outline: 0;
}

.carousel.carousel-slider .control-arrow {
  background: #000 !important;
  height: 50px !important;
  position: absolute;
  top: 35% !important;
}

.mobile-testimonial {
  display: none;
}

.desktop-testimonial {
  margin-left: 24px;
  margin-right: 24px;
}

@media only screen and (max-width: 934px) {
  .carousel.carousel-slider .control-arrow {
    display: none !important;
  }
  .myCarousel {
    background: #fafafa;
    margin-top: -9%;
    width: 88%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 8%;
    padding-bottom: 12.5%;
    padding-left: 5%;
    padding-right: 5%;
    border: 1px solid #ddd;
    min-height: 269px;
  }

  .testimonial-img {
    width: 24% !important;
    border-radius: 50%;
  }
}

@media (max-width: 991px) {
  .testimonial-container {
    padding-top: 50px;
    padding-bottom: 50px;
    align-items: center;
    justify-content: center;
  }
  .heading-text {
    font-size: 32px;
    margin-bottom: 40px !important;
  }
  .desktop-testimonial {
    display: none;
  }
  .mobile-testimonial {
    display: block;
  }
  .myCarousel p {
    margin: 0;
    padding: 0;
  }
  .myCarousel {
    background: #fafafa;
    margin-top: -9%;
    width: 75%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 8%;
    padding-bottom: 12.5%;
    padding-left: 5%;
    padding-right: 5%;
    border: 1px solid #ddd;
    min-height: 269px;
  }
}
@media (max-width: 500px) {
  .myCarousel {
    min-height: 55vh;
  }
}
