.row-1 {
  align-items: flex-start;
}

.row-2 {
  align-items: center;
}

.row-1 .image-block {
  justify-content: flex-start;
  margin-top: 30px;
}

.row-2 .image-block {
  justify-content: flex-end;
}

.row-2 .desc-text {
  margin-bottom: 0;
}

.about-accordion {
  margin-top: 50px;
}

.heading-title {
  text-align: left !important;
  margin-top: 40px;
  font-size: 40px;
}

@media (max-width: 767px) {
  .heading-title {
    text-align: center !important;
    margin-top: 36px;
    font-size: 32px;
  }
}

@media (max-width: 1200px) {
  .row-1 {
    align-items: center;
  }
}

@media (max-width: 991px) {
  .row-2 {
    flex-direction: column-reverse;
  }
  .row-1 .image-block {
    justify-content: center;
    margin-top: 0;
  }
  .row-2 .image-block {
    justify-content: center;
  }
}
