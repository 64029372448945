.desktop-gallery {
  display: grid !important;
  grid-template-columns: auto auto !important;
}

.content {
  padding-top: 24px;
  padding-bottom: 24px;
}

.desktop-img {
  width: 100%;
  padding-right: 5px;
  padding-left: 5px;
  padding-bottom: 10px;
}

.mobile-gallery {
  display: grid !important;
  grid-template-columns: auto !important;
}

@media (max-width: 767px) {
  .content {
    padding-top: 12px;
    padding-bottom: 12px;
  }
}
