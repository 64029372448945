.gallery-container {
  padding-top: 80px;
  padding-bottom: 80px;
}

.heading-text {
  font-size: 40px;
  text-align: center;
  margin-bottom: 55px;
}

.gallery-row {
  align-items: center;
  justify-content: center;
  text-align: center;
}

.galleryCarousel {
  background: rgba(196, 196, 196, 0.05) !important;
  margin-top: 0 !important;
}

.myGalleryCarousel {
  background: #fafafa;
  margin-top: -6%;
  width: 75%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 6%;
  padding-bottom: 8%;
  padding-left: 5%;
  padding-right: 5%;
  border: 1px solid #ddd;
  height: auto;
}

.myGalleryCarousel h3 {
  font-size: 20px;
}

.myGalleryCarousel h4 {
  font-size: 15px;
}

.myGalleryCarousel p {
  font-weight: 100 !important;
  line-height: 29px !important;
  color: #222;
  font-size: 15px;
  font-family: sans-serif;
  min-height: 67px;
}

.myGalleryCarousel p:before {
  content: "“";
  color: #aaa;
  font-size: 26px;
  font-family: monospace;
  font-weight: 100;
}

.myGalleryCarousel p:after {
  content: "”";
  color: #aaa;
  font-size: 26px;
  font-family: monospace;
  font-weight: 100;
  line-height: 0;
}

@media (max-width: 991px) {
  .myGalleryCarousel p {
    margin: 0;
    padding: 0;
  }
  .myGalleryCarousel {
    background: #fafafa;
    margin-top: -9%;
    width: 75%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 8%;
    padding-bottom: 12.5%;
    padding-left: 5%;
    padding-right: 5%;
    border: 1px solid #ddd;
    min-height: 269px;
  }
}

@media (max-width: 767px) {
  .gallery-container {
    padding-top: 50px;
    padding-bottom: 50px;
    align-items: center;
    justify-content: center;
  }
  .heading-text {
    font-size: 32px;
    margin-bottom: 40px !important;
  }
}
