.navigation-header-container {
  background-color: #4053fd;
  height: 225px;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.home-breadcrumb {
  text-decoration: none !important;
  color: #fff !important;
  font-family: "PlusJakartaDisplay-Medium", sans-serif;
  margin-right: 8px;
  cursor: pointer;
}

.active-breadcrumb {
  text-decoration: none !important;
  color: #fcfcfc !important;
  font-family: "PlusJakartaDisplay-Regular", sans-serif;
}

.breadcrumbs {
  display: flex;
  flex-direction: row;
}

.title-breadcrumb {
  font-size: 32px;
}

@media (max-width: 455px) {
  .home-breadcrumb {
    font-size: 14px;
  }
  .active-breadcrumb {
    font-size: 14px;
  }
  .title-breadcrumb {
    font-size: 24px;
  }
  .navigation-header-container {
    height: 150px;
  }
}
