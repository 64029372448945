label {
  font-family: "PlusJakartaDisplay-Regular", sans-serif;
}

input {
  font-family: "PlusJakartaDisplay-Regular", sans-serif;
}

input[type="text"] {
  outline: none !important;
  width: 80%;
  margin-top: 8px;
  margin-bottom: 8px;
  text-align: center;
  height: 36px;
}

input[type="email"] {
  width: 80%;
  outline: none !important;
  margin-top: 8px;
  text-align: center;
  margin-bottom: 8px;
  height: 36px;
}

textarea[type="text"] {
  width: 80%;
  outline: none !important;
  margin-bottom: 12px;
  margin-top: 8px;
  text-align: center;
  margin-bottom: 8px;
  height: 92px;
}

.contact-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
  margin-bottom: 24px;
}

.submit-btn {
  text-decoration: none;
  font-size: 14px;
  padding-top: 6px !important;
  padding-bottom: 6px !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
  border-radius: 4px !important;
  transition: 0.25s;
  background-color: #fff;
  margin-top: 12px;
}

.submit-text {
}

.submit-btn:hover {
  border-color: #fff !important;
  background-color: #4053fd;
  color: #fff !important;
}

@media (max-width: 378px) {
  .submit-btn {
    text-decoration: none;
    font-size: 14px;
    padding-top: 18px !important;
    padding-bottom: 18px !important;
    padding-left: 25px !important;
    padding-right: 25px !important;
    border-radius: 10px !important;
    transition: 0.25s;
  }
}
