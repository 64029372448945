.accordion-header {
  font-family: "PlusJakartaDisplay-Regular", sans-serif !important;
}

.accordion-body ul li {
  font-family: "PlusJakartaDisplay-Regular", sans-serif !important;
  margin-bottom: 16px;
}

.accordion-item {
  border: none !important;
}

.accordion-button:not(.collapsed) {
  background-color: transparent !important;
}

.accordion-button {
  outline: none !important;
}

.accordion-button:focus {
  border: none !important;
}
