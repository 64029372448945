.footer-container {
  display: flex;
  flex-direction: column;
  padding-left: 180px;
  padding-right: 180px;
  padding-top: 70px;
  padding-bottom: 25px;
}

.row-center-between {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.col-center {
  display: flex;
  flex-direction: column;
  align-items: baseline;
}

.mb20 {
  margin-bottom: 20px;
}

.footer-data {
  font-size: 14px;
  line-height: 18px;
  margin: 0px;
  margin-left: 20px;
}

.social-media-view {
  margin-top: 40px;
}

.social-media-label {
  font-size: 14px;
  line-height: 18px;
  margin: 0px;
}

.social-media-btn {
  outline: none !important;
  border: none !important;
  cursor: pointer !important;
  background-color: transparent !important;
  margin-left: 8px;
  margin-right: 8px;
}

.logo-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.separator {
  height: 2px;
  width: 100%;
  opacity: 0.05;
  margin-top: 85px;
}

.copyright {
  font-size: 14px;
  line-height: 18px;
  text-align: center;
}

.mt24 {
  margin-top: 24px;
}

@media (max-width: 991px) {
  .footer-container {
    padding-left: 85px;
    padding-right: 85px;
    padding-top: 50px;
  }
}

@media (max-width: 767px) {
  .footer-container {
    padding-left: 0;
    padding-right: 0;
  }
  .logo-wrapper {
    margin-bottom: 36px;
  }
  .col-center {
    padding-left: 35px;
    padding-right: 35px;
  }
  .separator {
    margin-top: 36px;
    margin-bottom: 12px;
  }
}
